import React from "react"
import styled from "styled-components"
import logoTAYAMA from "../../static/nowe_logo.png"

const Wrapper = styled.footer`
  text-align: center;
  padding: 6px;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 999;
  bottom: 0;
  left: 0;
  display: block;
  max-height: 10vw;
  background-color: ${props => props.theme.tayamaSzary};
  color: white;
  border-top: 1px solid white;
  .logoTAYAMA {
    max-height: 4vh;
    padding: 0.5vh;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <a href='https://www.tayama.pl'>
        <img src={logoTAYAMA} className='logoTAYAMA' alt='logo TAYAMA' />
      </a>
    </Wrapper>
  )
}

export default Footer
