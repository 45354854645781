import React from "react"
import Layout from "../components/Layout"
import Ecolumen from "../components/Ecolumen"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO></SEO>
    <Ecolumen></Ecolumen>
  </Layout>
)
