import React from "react"
// import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import tlo from "../../static/ecolumen_splash_bg.jpg"
import Img from "gatsby-image"
// import { BlobServiceClient } from "@azure/storage-blob"

const Wrapper = styled.div`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y repeat-x;
  background-color: rgb(0, 24, 51);
  padding: 0;
  .naglowek {
    width: 100%;
    text-align: center;
    color: white;
    display: block;
    background-color: ${props => props.theme.tayamaSzary};
  }
`
const Ecolumen = () => {
  const data = useStaticQuery(graphql`
    query produkty {
      allFile(
        filter: { relativePath: { regex: "/^920/i" } }
        sort: { fields: relativePath }
      ) {
        nodes {
          name
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  // const [pliki, setPliki] = useState([])
  // useEffect(() => {
  //   async function AzureTayama (setPliki) {
  //     const blobServiceClient = new BlobServiceClient(
  //       `https://tayama.blob.core.windows.net`
  //     )
  //     //      const urlSas = `https://tayama.blob.core.windows.net/?sv=2019-12-12&ss=b&srt=sco&sp=rl&se=2021-09-04T20:38:38Z&st=2020-09-04T12:38:38Z&spr=https,http&sig=67%2BThMpUTimyDFK8gpwOEer9F66%2BQ7siS0CUN4KgXwU%3D`
  //     //    const blobServiceClient = new BlobServiceClient(urlSas)
  //     let kontenery = []
  //     let iter = blobServiceClient.listContainers()
  //     let containerItem = await iter.next()
  //     while (!containerItem.done) {
  //       kontenery.push(containerItem.value.name)
  //       containerItem = await iter.next()
  //     }
  //     console.log(kontenery)
  //     let pl = []
  //     let containerClient = blobServiceClient.getContainerClient(`ecolumen`)
  //     let iterComposite = containerClient.listItems()
  //     let blobItem = await iterComposite.next()
  //     while (!blobItem.done) {
  //       pl.push(`https://cdn.tayama.pl/ecolumen/` + blobItem.value.name)
  //       blobItem = await iterComposite.next()
  //     }
  //     setPliki(pl)
  //   }
  //   //    AzureTayama(setPliki)
  // }, [])

  return (
    <Wrapper
      className='grid-container fluid'
      style={{ backgroundImage: `url(${tlo})`, padding: 0 }}
    >
      <div className='grid-x align-top'>
        <h1 className='naglowek'>CAST</h1>
      </div>
      <div className='grid-x align-top'>
        {data.allFile.nodes
          .filter(obr => {
            return obr.relativePath.includes("9201")
          })
          .map((item, index) => {
            return (
              <div className='cell small-6 medium-4 large-3' key={index}>
                <a
                  href={
                    "https://sklep.tayama.pl/oferta-handlowa,39?search=" +
                    item.name
                  }
                >
                  <Img fluid={item.childImageSharp.fluid} alt='CAST' />
                </a>
              </div>
            )
          })}
      </div>
      <div className='grid-x align-top'>
        <h1 className='naglowek'>LUNA</h1>
      </div>
      <div className='grid-x align-top'>
        {data.allFile.nodes
          .filter(obr => {
            return obr.relativePath.includes("9206")
          })
          .map((item, index) => {
            return (
              <div className='cell small-6 medium-4 large-3' key={index}>
                <a
                  href={
                    "https://sklep.tayama.pl/oferta-handlowa,39?search=" +
                    item.name
                  }
                >
                  <Img fluid={item.childImageSharp.fluid} alt='LUNA' />
                </a>
              </div>
            )
          })}
      </div>
    </Wrapper>
  )
}

export default Ecolumen
