import React from "react"
import styled from "styled-components"
import logoEco from "../../static/logoEco.png"

const Wrapper = styled.header`
  text-align: left;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 999;
  top: 0;
  left: 0;
  display: block;
  background-color: ${props => props.theme.tayamaEco};
  color: white;
  border-bottom: 1px solid white;
  .logoEco {
    max-height: 8vh;
    padding: 1.5vh;
    padding-left: 2vh;
  }
`
const Header = () => {
  return (
    <Wrapper>
      <a href='https://sklep.tayama.pl/oferta-handlowa/osprzet-elektroinstalacyjny,39,11386'>
        <img src={logoEco} className='logoEco' alt='logo ecolumen' />
      </a>
    </Wrapper>
  )
}

export default Header
